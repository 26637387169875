import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "rover-input-column pl-2" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "rover-input-column pl-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_InputText = _resolveComponent("InputText")!

  return (_ctx.isCalendarField)
    ? (_openBlock(), _createBlock(_component_Calendar, {
        key: 0,
        modelValue: _ctx.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
        showIcon: true,
        dateFormat: "mm-dd-yy",
        class: _normalizeClass(_ctx.inputClass),
        style: _normalizeStyle(_ctx.inputStyle),
        disabled: _ctx.isFieldReadOnly,
        placeholder: `Select ${_ctx.field.display_name}`,
        "selection-mode": _ctx.dateSelectionMode,
        onChange: _ctx.handleChange
      }, null, 8, ["modelValue", "class", "style", "disabled", "placeholder", "selection-mode", "onChange"]))
    : (_ctx.isMultiSelectField)
      ? (_openBlock(), _createBlock(_component_MultiSelect, {
          key: 1,
          modelValue: _ctx.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value) = $event)),
          options: _ctx.selectionOptions,
          optionLabel: _ctx.optionLabel,
          optionValue: _ctx.optionValue,
          placeholder: `Select ${_ctx.field.display_name}`,
          class: _normalizeClass(_ctx.inputClass),
          style: _normalizeStyle(_ctx.inputStyle),
          disabled: _ctx.isFieldReadOnly,
          onChange: _ctx.handleChange
        }, {
          option: _withCtx(({ option }) => [
            (_ctx.showOnlyCode)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(option[_ctx.optionValue]), 1))
              : (!_ctx.showSingleColumn)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createElementVNode("span", {
                      class: "rover-input-column",
                      style: _normalizeStyle({ width: _ctx.getLongestOptionWidth })
                    }, _toDisplayString(option[_ctx.optionValue]), 5),
                    _createElementVNode("span", _hoisted_3, _toDisplayString(option[_ctx.optionLabel]), 1)
                  ]))
                : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["modelValue", "options", "optionLabel", "optionValue", "placeholder", "class", "style", "disabled", "onChange"]))
      : (_ctx.isDropdownField)
        ? (_openBlock(), _createBlock(_component_Dropdown, {
            key: 2,
            "data-test": "dropdown",
            modelValue: _ctx.value,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.value) = $event)),
            options: _ctx.selectionOptions,
            optionLabel: _ctx.optionLabel,
            optionValue: _ctx.optionValue,
            placeholder: `Select ${_ctx.field.display_name}`,
            filter: "",
            class: _normalizeClass(_ctx.inputClass),
            style: _normalizeStyle(_ctx.inputStyle),
            disabled: _ctx.isFieldReadOnly,
            onChange: _ctx.handleChange
          }, {
            value: _withCtx(({ value }) => [
              (_ctx.showOnlyCode && value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(value), 1))
                : (value && !_ctx.showSingleColumn)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(value) + " - " + _toDisplayString(_ctx.getDescription(value)), 1))
                  : _createCommentVNode("", true)
            ]),
            option: _withCtx(({ option }) => [
              (_ctx.showOnlyCode)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(option[_ctx.optionValue]), 1))
                : (!_ctx.showSingleColumn)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createElementVNode("span", {
                        class: "rover-input-column",
                        style: _normalizeStyle({ width: _ctx.getLongestOptionWidth })
                      }, _toDisplayString(option[_ctx.optionValue]), 5),
                      _createElementVNode("span", _hoisted_8, _toDisplayString(option[_ctx.optionLabel]), 1)
                    ]))
                  : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["modelValue", "options", "optionLabel", "optionValue", "placeholder", "class", "style", "disabled", "onChange"]))
        : (_ctx.isNumberField)
          ? (_openBlock(), _createBlock(_component_InputNumber, {
              key: 3,
              modelValue: _ctx.value,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.value) = $event)),
              class: _normalizeClass(_ctx.inputClass),
              style: _normalizeStyle(_ctx.inputStyle),
              disabled: _ctx.isFieldReadOnly,
              "min-fraction-digits": _ctx.factionDigits,
              "max-fraction-digits": _ctx.factionDigits,
              onBlur: _ctx.handleChange,
              placeholder: `Enter ${_ctx.field.display_name}`
            }, null, 8, ["modelValue", "class", "style", "disabled", "min-fraction-digits", "max-fraction-digits", "onBlur", "placeholder"]))
          : (_ctx.isBooleanField)
            ? (_openBlock(), _createBlock(_component_InputSwitch, {
                key: 4,
                "true-value": _ctx.booleanTrueValue,
                "false-value": _ctx.booleanFalseValue,
                class: _normalizeClass(_ctx.inputClass),
                style: _normalizeStyle(_ctx.inputStyle),
                disabled: _ctx.isFieldReadOnly,
                onChange: _ctx.handleChange,
                modelValue: _ctx.value,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.value) = $event))
              }, null, 8, ["true-value", "false-value", "class", "style", "disabled", "onChange", "modelValue"]))
            : (_openBlock(), _createBlock(_component_InputText, {
                key: 5,
                type: "text",
                modelValue: _ctx.value,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.value) = $event)),
                class: _normalizeClass(_ctx.inputClass),
                style: _normalizeStyle(_ctx.inputStyle),
                disabled: _ctx.isFieldReadOnly,
                onBlur: _ctx.handleChange,
                placeholder: `Enter ${_ctx.field.display_name}`
              }, null, 8, ["modelValue", "class", "style", "disabled", "onBlur", "placeholder"]))
}