import ApiService from "./ApiService";

export class LookupRequest {
  file?: string;
  Id?: string;
  lookupName?: string;
  parameters?: any;
  Client?: string;
  sorts = {} as any;
  filters = {} as any;
  fieldnameFilters = {} as any;
  rangeStart?: string;
  rangeEnd?: string;
  fullRecords?: boolean;
}

export default class LookupService extends ApiService {
  constructor() {
    super();
  }

  async fetchLookupData(lookupRequest: Partial<LookupRequest>) {
    const response = await this.Post("lookup/doLookup", lookupRequest);
    return response;
  }
}
