import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, createBlock as _createBlock, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/images/productDefaultImg.svg'


const _hoisted_1 = { class: "p-input-icon-left p-0 m-0 col-12 md:col-4 flex align-items-center" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = {
  key: 1,
  class: "product-image",
  src: "",
  alt: "No image found"
}
const _hoisted_4 = { class: "grid p-0 m-0" }
const _hoisted_5 = { class: "kit-description block col-12 p-0 m-0" }
const _hoisted_6 = {
  key: 0,
  class: "part-number blue-text block col-12 p-0 m-0"
}
const _hoisted_7 = {
  key: 1,
  class: "part-number blue-text block emphasized"
}
const _hoisted_8 = { class: "part-manufactured-description block col-12 p-0 m-0" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "w-full" }
const _hoisted_12 = { class: "kit-description block" }
const _hoisted_13 = { class: "part-manufactured-description block" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { key: 1 }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { class: "grid" }
const _hoisted_18 = {
  key: 0,
  class: "text-right pb-0 col-12"
}
const _hoisted_19 = {
  key: 1,
  class: "text-right pb-2 col-12",
  style: { color: '#6c757d', fontSize: 'larger' }
}
const _hoisted_20 = { class: "grid" }
const _hoisted_21 = {
  key: 0,
  class: "text-right pb-2 col-12",
  style: { color: '#6c757d', fontSize: 'larger' }
}
const _hoisted_22 = { class: "flex p-0 m-0 justify-content-center" }
const _hoisted_23 = {
  class: "flex justify-content-center",
  style: { width: '40px' }
}
const _hoisted_24 = ["onClick"]
const _hoisted_25 = ["title"]
const _hoisted_26 = { key: 0 }
const _hoisted_27 = { class: "kit-table-wrapper" }
const _hoisted_28 = { class: "kit-description block" }
const _hoisted_29 = { class: "blue-text text-sm block" }
const _hoisted_30 = { class: "part-manufactured-description block" }
const _hoisted_31 = {
  class: "grid p-0 m-0",
  style: {"flex-wrap":"nowrap"}
}
const _hoisted_32 = ["onClick"]
const _hoisted_33 = {
  style: {"align-content":"space-evenly"},
  class: "m-0 p-0 mr-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_Tag = _resolveComponent("Tag")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_LookupDataTable = _resolveComponent("LookupDataTable")!
  const _component_LookupPartNumber = _resolveComponent("LookupPartNumber")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.lookupTable.lookupName)
      ? (_openBlock(), _createBlock(_component_DataTable, {
          key: 0,
          ref: "partsTableRef",
          value: _ctx.data,
          editingRows: _ctx.editingItems,
          "onUpdate:editingRows": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editingItems) = $event)),
          filters: _ctx.filters,
          "onUpdate:filters": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.filters) = $event)),
          expandedRows: _ctx.expandedRows,
          "onUpdate:expandedRows": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.expandedRows) = $event)),
          first: _ctx.firstItem,
          "onUpdate:first": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.firstItem) = $event)),
          editMode: _ctx.editMode,
          scrollable: true,
          scrollHeight: _ctx.scrollHeight,
          class: _normalizeClass(_ctx.getDataTableClass),
          "row-class": _ctx.rowClass,
          dataKey: "part_number",
          "removable-sort": "",
          filterDisplay: "row",
          globalFilterFields: ['part_number', 'desc'],
          responsiveLayout: "stack",
          breakpoint: "1200px",
          stripedRows: "",
          onRowClick: _ctx.onRowClick,
          onPage: _ctx.onPage,
          rows: _ctx.rowsPerPage,
          "onUpdate:rows": _cache[11] || (_cache[11] = ($event: any) => {
      _ctx.rowsPerPage = $event;
      _ctx.$emit('update:rows', $event);
    }),
          paginator: _ctx.showPaginator,
          paginatorTemplate: "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
          rowsPerPageOptions: [25, 50, 75],
          currentPageReportTemplate: "Showing {first} to {last} of {totalRecords} Products"
        }, {
          empty: _withCtx(() => _cache[15] || (_cache[15] = [
            _createElementVNode("div", { class: "p-text-center" }, [
              _createElementVNode("span", { class: "p-text-bold" }, "No Items Found")
            ], -1)
          ])),
          header: _withCtx(() => [
            _withDirectives(_createElementVNode("span", _hoisted_1, [
              _cache[16] || (_cache[16] = _createElementVNode("i", { class: "pi pi-search pl-2" }, null, -1)),
              _createVNode(_component_InputText, {
                modelValue: _ctx.filters['global'].value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filters['global'].value) = $event)),
                placeholder: "Keyword Search",
                class: "m-0"
              }, null, 8, ["modelValue"])
            ], 512), [
              [_vShow, _ctx.getPosShowKeywordSearch]
            ])
          ]),
          expansion: _withCtx((slotProps) => [
            _createTextVNode(_toDisplayString(slotProps) + " ", 1),
            _createElementVNode("div", _hoisted_27, [
              _createVNode(_component_DataTable, {
                class: "p-datatable-sm",
                value: slotProps.data.kitList
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Column, {
                    class: "product-image-kit-column",
                    style: { minWidth: '100px' }
                  }, {
                    body: _withCtx(() => _cache[17] || (_cache[17] = [
                      _createElementVNode("img", {
                        class: "product-image-kit",
                        src: _imports_0,
                        alt: "product image"
                      }, null, -1)
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: "name",
                    class: "flex flex-column align-items-start justify-content-center name-kit-column xl:w-4"
                  }, {
                    body: _withCtx((slotProps) => [
                      _createElementVNode("span", _hoisted_28, _toDisplayString(slotProps.data.kit), 1),
                      _createElementVNode("span", _hoisted_29, _toDisplayString(slotProps.data.name), 1),
                      _createElementVNode("span", _hoisted_30, _toDisplayString(_ctx.partNumberLabel) + " " + _toDisplayString(slotProps.data.part) + " | Manufactured Part #" + _toDisplayString(slotProps.data.manufacturedPart), 1)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_Column, {
                    field: "quantity",
                    class: "w-2"
                  }, {
                    body: _withCtx((slotProps) => [
                      _createElementVNode("div", null, [
                        _createVNode(_component_InputNumber, {
                          value: slotProps.data.quantity,
                          class: "quantity-input",
                          showButtons: "",
                          inputProps: { inputmode: 'numeric' },
                          buttonLayout: "horizontal",
                          "increment-button-icon": "pi pi-plus",
                          "decrement-button-icon": "pi pi-minus",
                          minFractionDigits: 
                    _ctx.useFractionalQuantities(slotProps.data) ? 1 : undefined
                  ,
                          onInput: ($event: any) => (_ctx.updatePrice($event, slotProps.data))
                        }, null, 8, ["value", "minFractionDigits", "onInput"])
                      ])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_Column, {
                    class: "blue-text text-sm font-bold",
                    field: "finalPrice"
                  }, {
                    body: _withCtx((slotProps) => [
                      _createElementVNode("span", null, "$" + _toDisplayString(slotProps.data.price), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["value"])
            ])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              class: "product-image-column",
              style: { minWidth: '100px' }
            }, {
              body: _withCtx((slotProps) => [
                (slotProps.data.image)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      class: "product-image",
                      src: slotProps.data.image,
                      alt: slotProps.data.desc
                    }, null, 8, _hoisted_2))
                  : (_openBlock(), _createElementBlock("img", _hoisted_3))
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "part_number",
              class: "flex flex-column align-items-baseline kit-description-wrapper",
              editable: ""
            }, {
              body: _withCtx((slotProps) => [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("span", _hoisted_5, _toDisplayString(slotProps.data.kit), 1),
                  (slotProps.data.desc)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(slotProps.data.desc), 1))
                    : (_openBlock(), _createElementBlock("span", _hoisted_7, "No description available")),
                  _createElementVNode("span", _hoisted_8, [
                    _createTextVNode(_toDisplayString(_ctx.partNumberLabel) + " " + _toDisplayString(slotProps.data.part_number) + " ", 1),
                    (slotProps.data.upc)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_9, "| UPC #" + _toDisplayString(slotProps.data.upc), 1))
                      : _createCommentVNode("", true),
                    (slotProps.data.code)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_10, "| Code #" + _toDisplayString(slotProps.data.code), 1))
                      : _createCommentVNode("", true),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.partIdFields, (field) => {
                      return (_openBlock(), _createElementBlock("span", {
                        key: field.field_no
                      }, " | " + _toDisplayString(field.label) + " " + _toDisplayString(slotProps.data[field.json_name]), 1))
                    }), 128))
                  ])
                ])
              ]),
              editor: _withCtx(({ data }) => [
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("span", _hoisted_12, _toDisplayString(data.kit), 1),
                  _createVNode(_component_Textarea, {
                    modelValue: data.desc,
                    "onUpdate:modelValue": ($event: any) => ((data.desc) = $event),
                    class: "mb-1 w-full",
                    onFocus: _cache[1] || (_cache[1] = ($event: any) => ($event.target.select()))
                  }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                  _createElementVNode("span", _hoisted_13, [
                    _createTextVNode(_toDisplayString(_ctx.partNumberLabel) + " " + _toDisplayString(data.part_number) + " ", 1),
                    (data.upc)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_14, "| UPC #" + _toDisplayString(data.upc), 1))
                      : _createCommentVNode("", true),
                    (data.code)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_15, "| Code #" + _toDisplayString(data.code), 1))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              class: "justify-content-center tag-column",
              field: "onHand"
            }, {
              body: _withCtx(({ data }) => [
                _createElementVNode("div", {
                  class: "result-grid-wrapper",
                  onClick: ($event: any) => (_ctx.showPartInventory($event, data)),
                  style: {"cursor":"pointer"}
                }, [
                  _createVNode(_component_Tag, {
                    severity: 
              _ctx.handleOnHandTagColor(
                data.avail_qty !== undefined ? data.avail_qty : 0,
              )
            ,
                    value: 
              _ctx.handleOnHandTagText(
                data.avail_qty !== undefined ? data.avail_qty : 0,
              )
            ,
                    rounded: "",
                    class: "text-center on-hand-tag"
                  }, null, 8, ["severity", "value"])
                ], 8, _hoisted_16)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              class: "blue-text font-bold code-price-wrapper",
              field: "price",
              "body-class": "text-right pr-2 xl:pr-5"
            }, {
              body: _withCtx((slotProps) => [
                _createElementVNode("div", _hoisted_17, [
                  (slotProps.data.price)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(_ctx.formatPrice(slotProps.data.price)), 1))
                    : _createCommentVNode("", true),
                  (
              _ctx.editable && slotProps.data.price && slotProps.data.quantity > 1
            )
                    ? (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(_ctx.formatPrice(
                slotProps.data.quantity * parseFloat(slotProps.data.price),
              )), 1))
                    : _createCommentVNode("", true)
                ])
              ]),
              editor: _withCtx(({ data }) => [
                _createElementVNode("div", _hoisted_20, [
                  _createVNode(_component_InputNumber, {
                    modelValue: data.price,
                    "onUpdate:modelValue": ($event: any) => ((data.price) = $event),
                    class: "text-right pb-0 col-12",
                    style: { color: '#6c757d', fontSize: 'larger' },
                    inputProps: { inputmode: 'decimal' },
                    minFractionDigits: 2,
                    maxFractionDigits: 2,
                    min: 0,
                    prefix: "$",
                    onFocus: _cache[2] || (_cache[2] = ($event: any) => ($event.target.select()))
                  }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                  (data.price && data.quantity > 1)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_21, _toDisplayString(_ctx.formatPrice(data.quantity * parseFloat(data.price))), 1))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "quantity",
              class: "quantity-wrapper",
              style: { width: '130px' }
            }, {
              body: _withCtx(({ data, index }) => [
                _createElementVNode("div", {
                  onClick: _cache[4] || (_cache[4] = ($event) => $event.stopPropagation())
                }, [
                  (!_ctx.editQtyDirectly)
                    ? (_openBlock(), _createBlock(_component_InputNumber, {
                        key: 0,
                        class: "quantity-input border-round-md",
                        modelValue: data.quantity,
                        "onUpdate:modelValue": ($event: any) => ((data.quantity) = $event),
                        disabled: true,
                        inputProps: { inputmode: 'numeric' },
                        inputStyle: { borderRadius: '6px !important', minWidth: '70px' },
                        minFractionDigits: _ctx.useFractionalQuantities(data) ? 1 : undefined,
                        onInput: ($event: any) => (_ctx.handleDecimals($event, data))
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "minFractionDigits", "onInput"]))
                    : _createCommentVNode("", true),
                  (_ctx.editQtyDirectly)
                    ? (_openBlock(), _createBlock(_component_InputNumber, {
                        key: 1,
                        class: "quantity-input border-round-md",
                        modelValue: data.quantity,
                        "onUpdate:modelValue": ($event: any) => ((data.quantity) = $event),
                        ref: _ctx.quantityInput(index),
                        min: _ctx.useFractionalQuantities(data) ? 0 : _ctx.calculateStep(data),
                        inputProps: { inputmode: 'numeric' },
                        inputStyle: { minWidth: '60px !important' },
                        showButtons: "",
                        buttonLayout: "horizontal",
                        incrementButtonIcon: "pi pi-plus",
                        decrementButtonIcon: "pi pi-minus",
                        step: _ctx.calculateStep(data),
                        onFocus: _cache[3] || (_cache[3] = ($event: any) => ($event.target.select())),
                        onInput: ($event: any) => (_ctx.updatePrice($event, data)),
                        minFractionDigits: _ctx.useFractionalQuantities(data) ? 1 : undefined,
                        onKeydown: ($event: any) => (_ctx.handleKeyDown($event, data, index)),
                        onBlur: ($event: any) => (_ctx.validatePartQuantity($event, data))
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "min", "step", "onInput", "minFractionDigits", "onKeydown", "onBlur"]))
                    : _createCommentVNode("", true)
                ])
              ]),
              editor: _withCtx(({ data, field }) => [
                _createElementVNode("div", {
                  onClick: _cache[6] || (_cache[6] = ($event) => $event.stopPropagation())
                }, [
                  _createVNode(_component_InputNumber, {
                    modelValue: data[field],
                    "onUpdate:modelValue": ($event: any) => ((data[field]) = $event),
                    min: _ctx.calculateStep(data),
                    showButtons: "",
                    inputProps: { inputmode: 'numeric' },
                    buttonLayout: "horizontal",
                    incrementButtonIcon: "pi pi-plus",
                    decrementButtonIcon: "pi pi-minus",
                    step: _ctx.calculateStep(data),
                    onFocus: _cache[5] || (_cache[5] = ($event: any) => ($event.target.select())),
                    onInput: ($event: any) => (_ctx.updatePrice($event, data))
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "min", "step", "onInput"])
                ])
              ]),
              _: 1
            }),
            (_ctx.showAddToCartBtn)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 0,
                  class: "cart-wrapper"
                }, {
                  body: _withCtx((slotProps) => [
                    _createElementVNode("div", _hoisted_22, [
                      _createVNode(_component_Button, {
                        class: "hidden xl:inline-block add-to-cart-btn pl-2 pr-2 lg:pl-3 lg:pr-3",
                        style: _normalizeStyle(_ctx.buttonColor(slotProps.data.quantity)),
                        label: "Add to Cart",
                        onClick: ($event: any) => (_ctx.addItem(slotProps.data))
                      }, null, 8, ["style", "onClick"]),
                      _createVNode(_component_Button, {
                        icon: "pi pi-shopping-cart",
                        class: "xl:hidden m-0 w-3rem h-3rem",
                        onClick: ($event: any) => (_ctx.addItem(slotProps.data))
                      }, null, 8, ["onClick"])
                    ])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.removable)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 1,
                  class: "actions"
                }, {
                  body: _withCtx((slotProps) => [
                    _createElementVNode("div", _hoisted_23, [
                      _createElementVNode("span", {
                        class: "pb-2 delete-action",
                        style: {"z-index":"10"},
                        onClick: ($event: any) => (_ctx.removePart($event, slotProps.data.part_number))
                      }, [
                        _createElementVNode("i", {
                          title: `Remove ${this.partNumberLabel} ${slotProps.data.part_number}`,
                          class: "pi pi-trash pointer",
                          style: {"color":"red","display":"flex","justify-content":"center","padding-top":"1vh","font-size":"20px"}
                        }, null, 8, _hoisted_25)
                      ], 8, _hoisted_24)
                    ])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.editable && _ctx.editMode === 'row')
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 2,
                  class: "editor-column",
                  rowEditor: true,
                  style: {"width":"6%","min-width":"6rem"},
                  bodyStyle: "text-align:center"
                }, {
                  body: _withCtx((rowData) => [
                    (_ctx.isEditing(rowData.data))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                          _createVNode(_component_Button, {
                            icon: "pi pi-check",
                            class: "p-row-editor-save mr-1",
                            onClick: ($event: any) => (_ctx.handleRowSave(rowData))
                          }, null, 8, ["onClick"]),
                          _createVNode(_component_Button, {
                            icon: "pi pi-times",
                            class: "p-row-editor-cancel ml-1",
                            onClick: ($event: any) => (_ctx.handleCancelEdit(rowData))
                          }, null, 8, ["onClick"])
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["value", "editingRows", "filters", "expandedRows", "first", "editMode", "scrollHeight", "class", "row-class", "onRowClick", "onPage", "rows", "paginator"]))
      : (_openBlock(), _createBlock(_component_LookupDataTable, {
          key: 1,
          id: "part_no",
          ref: "partLookup",
          lookup: _ctx.lookupTable,
          params: _ctx.lookupParams,
          lazyLoadPagination: true,
          tableClass: "p-datatable-sm",
          columnHeaderClass: "font-bold text-base",
          "manual-load": true,
          onOnLoad: _ctx.handleLookupLoad,
          onRowClick: _ctx.lookupRowClick
        }, {
          actions: _withCtx(({ data, index }) => [
            _createElementVNode("div", _hoisted_31, [
              _createElementVNode("div", {
                class: "result-grid-wrapper m-0 p-0",
                onClick: ($event: any) => (_ctx.showPartInventory($event, data)),
                style: {"cursor":"pointer","align-content":"space-evenly"}
              }, [
                _createVNode(_component_Tag, {
                  severity: 
              _ctx.handleOnHandTagColor(
                data.avail_qty !== undefined ? data.avail_qty : 0,
              )
            ,
                  value: 
              _ctx.handleOnHandTagText(
                data.avail_qty !== undefined ? data.avail_qty : 0,
              )
            ,
                  rounded: "",
                  class: "text-center on-hand-tag"
                }, null, 8, ["severity", "value"])
              ], 8, _hoisted_32),
              _createElementVNode("div", _hoisted_33, [
                _createVNode(_component_InputNumber, {
                  ref: _ctx.quantityInput(index),
                  class: "quantity-input border-round-md",
                  style: {"height":"3rem"},
                  modelValue: data.quantity,
                  "onUpdate:modelValue": ($event: any) => ((data.quantity) = $event),
                  min: _ctx.useFractionalQuantities(data) ? 0 : _ctx.calculateStep(data),
                  inputProps: { inputmode: 'numeric' },
                  inputStyle: { minWidth: '60px !important', height: '100%' },
                  showButtons: "",
                  buttonLayout: "horizontal",
                  incrementButtonIcon: "pi pi-plus",
                  decrementButtonIcon: "pi pi-minus",
                  step: _ctx.calculateStep(data),
                  minFractionDigits: _ctx.useFractionalQuantities(data) ? 1 : undefined,
                  onKeydown: ($event: any) => (_ctx.handleKeyDown($event, data, index)),
                  onBlur: ($event: any) => (_ctx.validatePartQuantity($event, data)),
                  onFocus: _cache[12] || (_cache[12] = ($event: any) => ($event.target.select())),
                  onClick: _cache[13] || (_cache[13] = ($event) => $event.stopPropagation())
                }, null, 8, ["modelValue", "onUpdate:modelValue", "min", "step", "minFractionDigits", "onKeydown", "onBlur"])
              ]),
              _createVNode(_component_Button, {
                icon: "pi pi-shopping-cart",
                label: "Add to Cart",
                class: "pos-add-to-cart-button",
                onClick: ($event: any) => (_ctx.addLookupItem(data))
              }, null, 8, ["onClick"])
            ])
          ]),
          _: 1
        }, 8, ["lookup", "params", "onOnLoad", "onRowClick"])),
    _createVNode(_component_LookupPartNumber, {
      partNumber: _ctx.selectedPart,
      show: _ctx.showLookupPartNumberDialog,
      onOnClose: _cache[14] || (_cache[14] = ($event: any) => (_ctx.showLookupPartNumberDialog = false))
    }, null, 8, ["partNumber", "show"])
  ], 64))
}